// Imports - Node
import { defineRouting } from "next-intl/routing"
import { createNavigation } from "next-intl/navigation"

// The `pathnames` object holds pairs of internal and
// external paths. Based on the locale, the external
// paths are rewritten to the shared, internal ones.
const routing = defineRouting({
	locales: ["en", "tr"],
	defaultLocale: "en",
	localePrefix: "always",
	pathnames: {
		'/': '/',
		"/about": {
			en: "/about",
			tr: "/hakkimda"
		},
		"/contact": {
			en: "/contact",
			tr: "/iletisim"
		},
		"/works": {
			en: "/works",
			tr: "/calismalarim"
		},
		"/works/web-apps": {
			en: "/works/web-apps",
			tr: "/calismalarim/web-uygulamalari"
		},
		"/works/mobile-apps": {
			en: "/works/mobile-apps",
			tr: "/calismalarim/mobil-uygulamalar"
		},
		"/works/designs": {
			en: "/works/designs",
			tr: "/calismalarim/tasarimlar"
		},
		"/works/photography": {
			en: "/works/photography",
			tr: "/calismalarim/fotografcilik"
		},
		"/privacy-policy": {
			en: "/privacy-policy",
			tr: "/gizlilik-politikasi"
		}
	}
})

// Export Types
export type Locale = (typeof routing.locales)[number]
export type Pathnames = keyof typeof routing.pathnames

// Exports
export { routing }
export const {
	Link,
	redirect,
	usePathname,
	useRouter,
	getPathname
} = createNavigation(routing)
