"use client"

// Imports - Node
import { memo, useEffect } from "react"

type Props = {}

const PartialDust = memo(function PartialDust({ }: Props): React.ReactNode {
	function handleMouseMove(this: Document, event: MouseEvent) {
		const dusts = this.getElementById("dust-area")?.querySelectorAll("div")
		const movementX = -event.movementX * 3
		const movementY = -event.movementY * 3

		dusts?.forEach(dust => {
			dust.style.transform = `translate(${movementX}px, ${movementY}px)`
		})
	}

	useEffect(() => {
		document.addEventListener("mousemove", handleMouseMove)
		return () => { document.removeEventListener("mousemove", handleMouseMove) }
	}, [])

	return (
		<section id="dust-area" className="max-xl:hidden absolute top-0 left-0 w-screen h-screen overflow-hidden select-none pointer-events-none -z-30">
			<div className="absolute top-[20%] left-[9%] size-2 rounded-full bg-secondary transition-transform duration-300 ease-linear" />
			<div className="absolute top-[70%] left-[16%] size-1.5 rounded-full bg-secondary transition-transform duration-500 ease-linear" />
			<div className="absolute top-[33%] left-[21%] size-1 rounded-full bg-secondary transition-transform duration-300 ease-linear" />
			<div className="absolute top-[48%] left-[32%] size-2 rounded-full bg-secondary transition-transform duration-500 ease-linear" />
			<div className="absolute top-[12%] left-[39%] size-1 rounded-full bg-secondary transition-transform duration-300 ease-linear" />
			<div className="absolute top-[78%] left-[43%] size-1.5 rounded-full bg-secondary transition-transform duration-500 ease-linear" />
			<div className="absolute top-[91%] left-[58%] size-2 rounded-full bg-secondary transition-transform duration-500 ease-linear" />
			<div className="absolute top-[16%] left-[65%] size-1.5 rounded-full bg-secondary transition-transform duration-300 ease-linear" />
			<div className="absolute top-[44%] left-[72%] size-1 rounded-full bg-secondary transition-transform duration-300 ease-linear" />
			<div className="absolute top-[82%] left-[92%] size-1 rounded-full bg-secondary transition-transform duration-500 ease-linear" />
			<div className="absolute top-[10%] left-[88%] size-2 rounded-full bg-secondary transition-transform duration-300 ease-linear" />
		</section>
	)
})

// Exports
export default PartialDust
