"use client"

// Imports - Node
import { createContext, useContext, useState } from "react"

// Imports - Local
import PartialLanding from "@/components/_partials/landing"

type LandingValues = {
	active: boolean
	setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const LandingContext = createContext<LandingValues | null>(null)

function useLanding() {
	const context = useContext(LandingContext)
	if (!context) {
		throw new Error("useLanding must be used within LandingProvider")
	}
	return context
}

function LandingProvider({ children }: Readonly<{ children: React.ReactNode }>) {
	const [active, setActive] = useState<boolean>(true)
	const value: LandingValues = { active, setActive }

	return (
		<LandingContext.Provider value={value}>
			{children}
			<PartialLanding />
		</LandingContext.Provider>
	)
}

// Exports
export { LandingProvider as default, useLanding }
